<template>
  <main>
    <!-- Modal -->
    <div id="exampleModal" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">BARANG</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <v-text-field
              v-model="search"
              class="pt-5"
              label="Search"
              dense
              outlined
              @keyup="doSearch()"
            ></v-text-field>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase">NO</th>
                    <th class="text-center text-uppercase">Kode Barang</th>
                    <th class="text-center text-uppercase">Nama Barang</th>
                    <th class="text-center text-uppercase">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in displayData()" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td class="text-center">
                      {{ data.kode }}
                    </td>
                    <td class="text-center">
                      {{ data.nama_barang }}
                    </td>
                    <td class="text-center">
                      <v-btn
                        data-bs-dismiss="modal"
                        color="primary"
                        class="me-3 mt-4"
                        @click="CreateStockOpnameModal(data.kode)"
                      >
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiCheckBold }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6">
                      <center>
                        <div class="v-data-footer">
                          <span class="v-data-footer__icons-before" bis_skin_checked="1">
                            <button
                              type="button"
                              :class="[
                                'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                page == 1 ? 'v-btn--disabled' : '',
                              ]"
                              aria-label="Previous page"
                              @click="page--"
                            >
                              <span class="v-btn__content"
                                ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    role="img"
                                    aria-hidden="true"
                                    class="v-icon__svg"
                                  >
                                    <path
                                      d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                    ></path></svg></span
                              ></span>
                            </button>
                          </span>
                          <span
                            v-for="pageNumber in pages.slice(page - 1, page + 5)"
                            :key="pageNumber"
                            :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                            @click="page = pageNumber"
                          >
                            <button
                              type="button"
                              class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                              aria-label="Next page"
                            >
                              {{ pageNumber }}
                            </button>
                          </span>
                          <span class="v-data-footer__icons-after" bis_skin_checked="1">
                            <button
                              type="button"
                              :class="[
                                'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                page < pages.length ? '' : 'v-btn--disabled',
                              ]"
                              aria-label="Next page"
                              @click="page++"
                            >
                              <span class="v-btn__content"
                                ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    role="img"
                                    aria-hidden="true"
                                    class="v-icon__svg"
                                  >
                                    <path
                                      d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                    ></path></svg></span
                              ></span>
                            </button>
                          </span>
                        </div>
                      </center>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </div>
    <!-- end Modal -->

    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat>
      <v-row>
        <v-col cols="4" sm="6" md="5">
          <v-text-field
            style="padding-left: 15px"
            @keyup.enter="CreatestockopnameTemp()"
            v-model="stockopname.kode"
            label="Kode Barang / Scan Barcode"
            autofocus
          ></v-text-field>
        </v-col>
        <v-col md="1">
          <v-row align="center" justify="center">
            <v-btn color="primary" class="me-2 mt-4">
              <a data-bs-toggle="modal" data-bs-target="#exampleModal">
                <v-icon color="#ECEFF1">
                  {{ icons.mdiArchiveSearchOutline }}
                </v-icon>
              </a>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined tile>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 5px" class="text-uppercase">NO</th>
              <th style="width: 130px" class="text-center text-uppercase">KODE</th>
              <th style="width: 130px" class="text-center text-uppercase">NAMA BARANG</th>
              <th style="width: 130px" class="text-center text-uppercase">TGL SO</th>
              <th style="width: 90px" class="text-center text-uppercase">JUMLAH ASSET</th>
              <th style="width: 90px" class="text-center text-uppercase">JUMLAH STOCK</th>
              <th style="width: 170px" class="text-center text-uppercase">KETERANGAN</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in detailstockopnames" :key="index">
              <td>{{ 1 + index }}</td>
              <td class="text-center">{{ data.kode }}</td>
              <td class="text-center capitaltext">{{ data.nama_barang }}</td>
              <td class="text-center">{{ data.tgl_stockopname | date }}</td>
              <td class="text-center" style="padding-top: 10px">
                {{ data.jumlah_asset }}
              </td>
              <td class="text-center" style="padding-top: 10px">
                <v-text-field
                  @keyup.enter.prevent="
                    isNumber($event)
                    Updatestockopname(data.id_detail_stockopname, index)
                  "
                  v-model="data.jumlah_stok"
                  solo
                ></v-text-field>
              </td>
              <td class="text-center" style="padding-top: 10px">
                <v-text-field
                  label="Input keterangan"
                  @keyup.enter.prevent="Updatestockopname(data.id_detail_stockopname, index)"
                  v-model="data.keterangan"
                  solo
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </template> </v-simple-table
      ><br /><br />
    </v-card>
  </main>
</template>
<script>
import {
  mdiCheckBold,
  mdiCalendarMonth,
  mdiCalendarCheck,
  mdiAccountPlus,
  mdiAccountCheck,
  mdiEmailOutline,
  mdiCellphone,
  mdiCamera,
  mdiSlashForward,
  mdiArchiveSearchOutline,
  mdiDelete,
} from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/superadmin/dashboard',
        },
        {
          text: 'Detail Stockopname',
          disabled: false,
          href: '/superadmin/detailstockopname',
        },
        {
          text: 'Create Detail Stockopname',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiCalendarMonth,
        mdiCalendarCheck,
        mdiAccountPlus,
        mdiAccountCheck,
        mdiEmailOutline,
        mdiCellphone,
        mdiCamera,
        mdiSlashForward,
        mdiArchiveSearchOutline,
        mdiDelete,
      },

      detailstockopnames: [],

      stockopname: {
        kode: '',
      },
      // ppn: [],
      data: {
        tgl_stockopname: '',
        jumlah_asset: '',
        jumlah_stok: '',
        keterangan: '',
      },

      errors: [],
      databarang: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  created() {
    this.$isLoading(true)
    this.getAllBarang()
    this.getAllData()

    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },

  methods: {
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      }
    },
    resetAll() {
      this.stockopname.kode = ''
    },

    getAllBarang() {
      //state token
      localStorage.getItem('token')

      let uriBarangs = process.env.VUE_APP_ROOT_API + '/api/superadmin/barang-index'
      return this.axios.get(uriBarangs).then(response => {
        this.databarang = response.data.barangs
      })
    },

    CreateStockOpnameModal(kode) {
      let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/detailstockopname/store'
      this.axios
        .post(uri, {
          kode: kode,
        })
        .then(response => {
          this.$isLoading(true)
          setTimeout(() => {
            this.getAllData()
            this.resetAll()
            this.$isLoading(false)
          }, 2000)
        })
        .catch(e => {
          this.$swal.fire({
            title: 'Error!',
            html: 'Produk sudah diinput !',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          })
        })
    },

    getAllData() {
      localStorage.getItem('token')
      let Uristockopname = process.env.VUE_APP_ROOT_API + '/api/superadmin/detailstockopname-index'
      return this.axios.get(Uristockopname).then(response => {
        this.detailstockopnames = response.data.detailstockopname
      })
    },
    CreatestockopnameTemp() {
      let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/detailstockopname/store'
      this.axios.post(uri, this.stockopname).then(response => {
        if (response.data.message == true) {
          console.log('KODE ' + this.stockopname.kode)
          setTimeout(() => {
            this.getAllData()
            this.resetAll()
          }, 2000)
        } else if (response.data == false) {
          this.$swal.fire({
            title: 'Error!',
            html: 'Bukan masa input stock opname !',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          })
        }
      })
    },

    Updatestockopname(id, index) {
      console.log('KOSONG' + id)
      let ppns = 0
      this.detailstockopnames.push({ jumlah_stok: '', keterangan: '' })
      let uriUpdatestockopname = process.env.VUE_APP_ROOT_API + `/api/superadmin/detailstockopname/${id}/update`
      this.axios
        .post(uriUpdatestockopname, {
          jumlah_stok: this.detailstockopnames[index].jumlah_stok,
          keterangan: this.detailstockopnames[index].keterangan,
        })
        .then(response => {})

      setTimeout(() => {
        this.getAllData()
        this.getTotal()
      }, 1000)
    },

    deleteAllPost() {
      this.$swal
        .fire({
          title: 'Apakah anda yakin?',
          text: 'Jika anda hapus, maka data tidak akan kembali lagi.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            let uriDeleteAll = process.env.VUE_APP_ROOT_API + `/api/superadmin/detailstockopname/deleteall`
            this.axios
              .delete(uriDeleteAll)
              .then(response => {
                this.detailstockopnames.splice(this.detailstockopnames.index, 1)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Transaksi stockopname deleted successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                  }, 1000)
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, can not delete transaksi stockopname!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                  }, 1000)
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal.fire({
                  title: 'Error!',
                  text: 'Transaksi stockopname failed to deleted!',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000,
                })
                setTimeout(() => {
                  this.getAllData()
                }, 1000)
              })
          }
        })
      setTimeout(() => {
        this.getAllData()
        this.getTotal()
      }, 1000)
    },

    deletePost(id, index) {
      this.$swal
        .fire({
          title: 'Apakah anda yakin?',
          text: 'Jika anda hapus, maka data tidak akan kembali lagi.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            this.detailstockopnames.push({ id_detail_stockopname: '' })
            let uriDelete = process.env.VUE_APP_ROOT_API + `/api/superadmin/detailstockopname/${id}/delete`
            this.axios
              .delete(uriDelete)
              .then(response => {
                this.detailstockopnames.splice(this.detailstockopnames.index, 1)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Transaksi stockopname deleted successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                  }, 1000)
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, cann delete transaksi stockopname!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                  }, 1000)
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal.fire({
                  title: 'Error!',
                  text: 'transaksi stockopname failed to deleted!',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 2000,
                })
              })

            setTimeout(() => {
              this.getAllData()
              this.getTotal()
            }, 1000)
          }
        })
    },

    doSearch() {
      const go = this.databarang.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_barang.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },

    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.databarang)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.databarang.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(databarang) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return databarang.slice(from, to)
    },

    Pilihbarang(kode, nama_barang) {
      document.querySelector('#kode').setAttribute('value', kode)
      document.querySelector('#nama_barang').setAttribute('value', `${nama_barang}`)
      this.pembelian.kode = kode
      this.pembelian.nama_barang = nama_barang
    },
  },
  watch: {
    databarang() {
      this.pages = []
      this.setPages()
    },
  },
}
</script>
<style>
.uppertext {
  text-transform: uppercase;
}

.lowtext {
  text-transform: lowercase;
}

.capitaltext {
  text-transform: capitalize;
}
</style>
